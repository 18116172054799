import React, { useState, useEffect, useCallback } from 'react';
import { Stack, Dropdown, DetailsList, SearchBox, SelectionMode, PrimaryButton } from '@fluentui/react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import config from '../Admin/config';
import { connect } from 'react-redux';
import { useMsal } from '@azure/msal-react';

const VistaAdmin = ({ companyId }) => {
  const { t, i18n } = useTranslation();
  const [lenguaje, setLenguaje] = useState(i18n.language);
  const {accounts} = useMsal();

  const [searchValue, setSearchValue] = useState('');
  const [filteredData, setFilteredData] = useState([]);
  const [NitOptions, setNitOptions] = useState([]);
  const [companyData, setComapanyData] = useState({});
  const [excludedData, setExcludedData] = useState([]);
  const [costTypes, setCosttypes] = useState([]);
  const [costType, setCostType] = useState('');
  const [hasBaseData, setHasBaseData] = useState([]);
  
  const [taxProviderCollapsed, setTaxProviderCollapsed] = useState(true);
  const [excludedMarkupCollapsed, setExcludedMarkupCollapsed] = useState(true);
  const [taxBaseCollapsed, setTaxBaseCollapsed] = useState(true);


  const lenguajes = [
    { key: 'es', text: t('Admin.es') },
    { key: 'en', text: t('Admin.en') },
  ];

  const handleLenguajeChange = (_, item) => {
    setLenguaje(item.key);
    i18n.changeLanguage(item.key);
  };

  const handleSearch = (newValue) => {
    setSearchValue(newValue);

    const filtered = NitOptions.filter(
      (item) =>
        item.nit.includes(newValue) || item.providerName.toLowerCase().includes(newValue.toLowerCase())
    );

    setFilteredData(filtered);
  };

  const handleCostTypeChange = (_, item) => {
      setCostType(item ? item.key : '');
  };
  const columnsNit = [
    { key: 'nit', name: 'Nit', fieldName: 'nit', minWidth: 100, maxWidth: 200 },
    { key: 'name', name: t('Costs.Name'), fieldName: 'providerName', minWidth: 100, maxWidth: 200 },
    {
      key: 'actions',
      name: 'actions',
      minWidth: 180,
      maxWidth: 200,
      onRender: (item) => (
        <>
          <PrimaryButton onClick={()=> handleProviderSelection(item)}>Select</PrimaryButton>
        </>
      ),
    },
  ];

  const handleDeleteCostType = async (costTypeId) => {
    try {
      await axios.delete(`${config.apiUrl}api/cost/type/${costTypeId}`);
      fetchExcludedData()
   } catch (error) {
       alert(t('Costs.Error_delete'))// Handle the error gracefully here, e.g., show an error message to the user
   }
  };

  const handleDeleteCostTypeHasBase = async (costTypeId) => {
    try {
      await axios.delete(`${config.apiUrl}api/cost/type/hasBase/${costTypeId}`);
      fetchHasBaseData()
   } catch (error) {
       alert(t('Costs.Error_delete'))// Handle the error gracefully here, e.g., show an error message to the user
   }
  };
  const handleProviderSelection = async (item) => {
    try {
      await axios.put(`${config.apiUrl}api/companies/${companyId}?taxProvider=${item.id}`);
      fetchCompanyData()
      setSearchValue('')
    } catch (error) {
      console.error('Error updating data:', error);
    }
  };
  const handleSubmitCostType = async () => {
    try {
      await axios.post(`${config.apiUrl}api/cost/type`, {companyId : companyId, costType: costType});
      setCostType('')
      fetchExcludedData()
    } catch (error) {
        alert(t('Costs.Error_delete'))// Handle the error gracefully here, e.g., show an error message to the user
    }
  };

  const handleSubmitCostTypehasBase = async () => {
    try {
      await axios.post(`${config.apiUrl}api/cost/type/hasBase`, {companyId : companyId, costType: costType});
      setCostType('')
      fetchHasBaseData()
    } catch (error) {
        alert(t('Costs.Error_delete'))// Handle the error gracefully here, e.g., show an error message to the user
    }
  };

  const columnsExcluded = [
    { key: 'CostType', name:  t('Admin.CostType'), fieldName: 'costType', minWidth: 100 },
    {
        key: 'opciones',
        name: t('Costs.Options'),
        fieldName: 'opciones',
        minWidth: 200,
        onRender: (item) => (
            <div>
                <PrimaryButton text={t('Costs.Delete')} onClick={() => handleDeleteCostType(item.exclusionId)} style={{ backgroundColor: '#f00' }} />
            </div>
        )
    }
];

  const columnsHasBase = [
    { key: 'CostType', name:  t('Admin.CostType'), fieldName: 'costType', minWidth: 100 },
      {
          key: 'opciones',
          name: t('Costs.Options'),
          fieldName: 'opciones',
          minWidth: 200,
          onRender: (item) => (
              <div>
                  <PrimaryButton text={t('Costs.Delete')} onClick={() => handleDeleteCostTypeHasBase(item.hasTaxBaseId)} style={{ backgroundColor: '#f00' }} />
              </div>
          )
      }
];



  useEffect(() => {
    const fetchNitsData = async () => {
      try {
        const response = await axios.get(`${config.apiUrl}api/providers/all`, {
          params: { company: companyId },
        });
        const data = response.data;
        setNitOptions(data);
      } catch (error) {
        console.error('Error fetching cost types data:', error);
      }
    };
    fetchNitsData();
  }, [companyId]);


  
  const fetchCompanyData = useCallback( async () => {
    try {
      const response = await axios.get(`${config.apiUrl}api/companies/${companyId}`);
      const data = response.data;
      setComapanyData(data)
    } catch (error) {
      console.error('Error fetching company data:', error);
    }
  },[companyId]);
  useEffect(() => {
    fetchCompanyData();
  }, [companyId, fetchCompanyData]);


  const fetchExcludedData = useCallback( async () => {
    try {
      const response = await axios.get(`${config.apiUrl}api/cost/type/excluded?company=${companyId}`);
      const data = response.data;
      setExcludedData(data)
    } catch (error) {
      console.error('Error fetching company data:', error);
    }
  },[companyId]);
  useEffect(() => {
    fetchExcludedData();
  }, [companyId, fetchExcludedData]);

  const fetchHasBaseData = useCallback( async () => {
    try {
      const response = await axios.get(`${config.apiUrl}api/cost/type/hasBase?company=${companyId}`);
      const data = response.data;
      setHasBaseData(data)
    } catch (error) {
      console.error('Error fetching company data:', error);
    }
  },[companyId]);
  useEffect(() => {
    fetchHasBaseData();
  }, [companyId, fetchHasBaseData]);

  useEffect(() => {
    const fetchCostTypeData = async () => {
        try {
            const response = await axios.get(`${config.apiUrl}api/cost/type`,{params:{company: companyId}});
            const data = response.data;

            const dropdownOptions = data.map((item) => ({
                key: item.idCostType,
                text: item.idCostType  + " - " + item.costTypeName,
            }));
            setCosttypes(dropdownOptions);
        } catch (error) {
            console.error('Error fetching cost types data:', error);
        }
    };
    fetchCostTypeData();
}, [companyId]);

  // Function to handle selection of a provider
  
  const roles = accounts[0]?.idTokenClaims?.roles;
  const isAdmin = roles.includes('SII_Admin');
  
  return (
    <div className="card">
      <h1>{t('Admin.Settings')}</h1>
      <Stack tokens={{ childrenGap: 10 }}>
        <Dropdown
          options={lenguajes}
          selectedKey={lenguaje}
          onChange={handleLenguajeChange}
          label={t('Admin.Languaje')}
        />
        {isAdmin && (
        <div>
          <h3>{t('Admin.CompanySetUp')}</h3>
          <h4 onClick={() => setTaxProviderCollapsed(!taxProviderCollapsed)}>{t('Admin.Taxprovider')}</h4>
          {!taxProviderCollapsed && (
            <div>
              <p>Actual: {companyData.taxVendor} </p>
              <SearchBox
                label = "nit"
                placeholder={t('Admin.NitName')}
                value={searchValue}
                onChange={(event, newValue) => handleSearch(newValue)}
              />
              {searchValue && (
                <DetailsList
                  items={filteredData}
                  selectionMode={SelectionMode.none}
                  columns={columnsNit}
                />
              )}
            </div>
          )}
          
          <h4 onClick={() => setExcludedMarkupCollapsed(!excludedMarkupCollapsed)}>{t('Admin.Excluded')}</h4>

          {!excludedMarkupCollapsed && (
            <div>
              <Dropdown
                      label={t('Costs.Type')}
                      options={costTypes}
                      onChange={handleCostTypeChange}
                      selectedKey={costType}
              />
              <PrimaryButton text={t('Admin.Exclude')} onClick={handleSubmitCostType} />
              <DetailsList
                  items={excludedData}
                  selectionMode={SelectionMode.none}
                  columns={columnsExcluded}
              />
            </div>
          )}
          
          
          <h4 onClick={() => setTaxBaseCollapsed(!taxBaseCollapsed)}>{t('Admin.TaxBase')}</h4>
          {!taxBaseCollapsed && (
            <div>
              <Dropdown
                    label={t('Costs.Type')}
                    options={costTypes}
                    onChange={handleCostTypeChange}
                    selectedKey={costType}
              />
              <PrimaryButton text={t('Admin.Add')} onClick={handleSubmitCostTypehasBase} />
              <DetailsList
                  items={hasBaseData}
                  selectionMode={SelectionMode.none}
                  columns={columnsHasBase}
              />
            </div>
          )}
        </div>
        )}
      
      </Stack>
    </div>
  );
};

const mapStateToProps = (state) => ({
  companyId: state.companyId,
});
export default connect(mapStateToProps)(VistaAdmin);
