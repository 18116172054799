import AdminRoutes from "./views/Admin/AdminRoutes";
import NotFound from "./views/sessions/NotFound";
import {Navigate} from "react-router-dom";

const routes = [
    { path: '/404', element: <NotFound /> },
    { path: '/', element: <Navigate to="dashboard" /> },
    { path: '*', element: <NotFound /> }
].concat(AdminRoutes)

export default routes;