import * as React from 'react';
import {
    mergeStyles,
    Icon,
    IconButton,
    PersonaSize,
    Persona,
    Stack,
    ContextualMenu,
    DirectionalHint,
    initializeIcons,
    Image,
    StackItem
} from '@fluentui/react';
import {useState, useEffect} from "react";
import {useNavigate} from "react-router-dom";
import CompanyDropdown from "./components/CompanyDropdown";
import { useMsal } from '@azure/msal-react';
import axios from 'axios';
import { PublicClientApplication } from '@azure/msal-browser';
import { msalConfig } from '../auth/authConfig';
import config from './views/Admin/config'
import {useTranslation} from 'react-i18next'




const msalInstance = new PublicClientApplication(msalConfig)

const topbarStyles = mergeStyles({
    backgroundColor: 'black',
    color: 'white',
    width: '100%',
    padding: '10px',
    display: 'flex',
    justifyContent: 'space-between', // Changed justifyContent to space-between
});



const Topbar = () => {
    const { accounts} = useMsal();
    const [name, setName] = useState('');
    const [options, setOptions] = useState('');
    const {t} = useTranslation();

    useEffect(() => {
        

        const fetchCompanyData = async () => {
            if (accounts.length > 0) {
                const account = accounts[0];
                const roles = account.idTokenClaims.roles;
                if (roles != null){
                    try {
                        const response = await axios.post(`${config.apiUrl}api/companies`, roles, {
                        });
                        const data = response.data
                        const dropdownOptions = data.map((item) => ({
                            key: item.companyId,
                            text: item.companyName,
                        }));
                        setOptions(dropdownOptions);
                    } catch (error) {
                        console.error('Error fetching companies data:', error);
                    }
                }
            }
        }
        fetchCompanyData();
    }, [accounts]);
    
    
    
    useEffect(() => {
        if (accounts.length > 0) {
            const account = accounts[0];
            const userName = account.name;
            setName(userName);
        }
    }, [accounts]);

    const logout = async () => {
        const logoutRequest = {
          account: msalInstance.getAccountByUsername('your_username') // Replace with the username of the currently logged-in user
        };
      
        try {
          await msalInstance.logoutPopup(logoutRequest);
          // Clear any local app-specific session state or user data as needed
        } catch (error) {
          alert('Logout error')  
        }
      };
      

    let navigate;
    navigate = useNavigate();
    initializeIcons()
    const menuItems = [
        {
            key: 'ajustes',
            text: t('Admin.Settings'),
            onClick: () => {
                navigate(`/admin`)
            },
        },
        {
            key: 'logout',
            text: 'Logout',
            onClick: () => {
                logout()
            },

        },
    ];
    const handleRedirect = () => {
        window.location.href = 'https://www.office.com';
    };
    const handleMenuClick = () => {
        setIsMenuOpen(!isMenuOpen);
    };
    const handleMenuDismiss = () => {
        setIsMenuOpen(false);
    };
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    return (
        <div className={topbarStyles}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <IconButton onClick={handleRedirect} style={{fontSize: '24px'}}>
                    <Icon iconName="Waffle" style={{ color: 'white' }} />
                </IconButton>
                <Stack horizontal tokens={{ childrenGap: 10 }}>
                    <StackItem>
                        <a href="/">
                            <Image
                                src="https://stsiiapptest001.blob.core.windows.net/siiapptest/cat.png"
                                alt="Cat"
                                style={{ width: '130px', height: '60px' }}
                            />
                        </a>
                    </StackItem>
                    <StackItem>
                        <p>BIENVENIDO: </p>
                    </StackItem>
                    <StackItem>
                        <p>{name}</p>
                    </StackItem>                               
                </Stack>
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <CompanyDropdown dropdownOptions={options}/>
                <div style={{ position: 'relative' }}>
                    <Stack horizontalAlign="end" horizontal>                       
                        <Stack.Item>
                            <Persona
                                size={PersonaSize.size32}
                                onClick={handleMenuClick}
                            />
                        </Stack.Item>
                    </Stack>
                    {isMenuOpen && (
                        <ContextualMenu
                            items={menuItems}
                            directionalHint={DirectionalHint.bottomLeftEdge}
                            target={document.querySelector('.ms-Persona')}
                            onDismiss={handleMenuDismiss}
                        />
                    )}
                </div>
            </div>
        </div>
    );
};

export default Topbar;
