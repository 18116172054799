import { createStore } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import persistConfig from './persistConfig'; // Assuming your persist config is exported from './persistConfig'

// Define your initial state
const initialState = {
    companyId: '',
};

// Define your reducer
const reducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_COMPANY_ID':
            return {
                ...state,
                companyId: action.payload
            };  
        default:
            return state;
    }
};

const persistedReducer = persistReducer(persistConfig, reducer);
// Create the store
const store = createStore(persistedReducer);

const persistor = persistStore(store);

export { store, persistor };
