import React, {useCallback, useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {PrimaryButton, TextField, Stack, DatePicker, Dropdown} from "@fluentui/react";
import axios from 'axios';
import {connect} from "react-redux";
import {useTranslation} from 'react-i18next'
import config from '../Admin/config'
import {useConst} from "@fluentui/react-hooks";



const FormulatioEditarDocumento = ({companyId}) => {
    const navigate = useNavigate();
    const { id } = useParams();
    const [NumeroLC, setNumeroLC]=useState('');
    const [FechaLC, setFechaLC]=useState(new Date().toISOString());
    const [Tasa, setTasa]=useState(1);
    const [TaxBase, setTaxBase]= useState(0);
    const [Levante, setLevante]=useState('');
    const [FechaLevante, setFechaLevante]=useState('');
    const [TipoMercancia, setTipoMercancia] = useState('');
    const [TransportMethod , setTransportMethod] = useState('');
    const [Provider , setProvider] = useState('');
    const [TransportDocumentDate, SetTransportDocumentDate] = useState('');
    const [ProviderId, SetProviderId] = useState('');
    const [Incoterm, SetIncoterm] = useState('');
    const [mediosTransporte, setMediosTransporte] = useState([]);

    const {t, i18n} = useTranslation();

    
    const fetchDocumentData = useCallback(
        async (companyId) => {
            try {
              const response = await axios.get(`${config.apiUrl}api/documents/${id}`, {
                params: {
                  company: companyId,
                  locale: i18n.language
                }
              });
        
              if (response.status === 404) {
                navigate(`/session/404`)
                } else {
                const data = response.data;
                setTransportMethod(data.transportMethodId);
                setProvider(data.providerName);
                SetTransportDocumentDate(data.transportDocumentDate);
                setTipoMercancia(data.reptoEquipo);
                setNumeroLC(data.landedCostNumber);
                setFechaLC(data.landedCostDate);
                setTasa(data.tasa);
                setTaxBase(data.taxBase);
                setLevante(data.levanteNumber);
                setFechaLevante(data.levanteDate);
                SetProviderId(data.providerId);
                SetIncoterm(data.icoterm);
              }
            } catch (error) {
              console.error('Error fetching document data:', error);
            }
        }, [id, i18n, navigate]);

    useEffect(() => {
        fetchDocumentData(companyId);
      }, [id, companyId, fetchDocumentData]);
    const handleLevanteChange = (event) => {
      setLevante(event.target.value);
    };
    const handleFechaLevanteChange = (date) => {
      if (date) {
        // Format the selected date in "YYYY-MM-DD" format without the time zone info
        const formattedDate = date.toISOString().split('T')[0];
        setFechaLevante(formattedDate);
      } else {
        setFechaLevante(null);
      }
    };
    const handleMedioTransporteChange = (_, item) => {
      setTransportMethod(item ? item.key : '');
  };
    const handleFechaChange = (date) => {
      if (date) {
        // Format the selected date in "YYYY-MM-DD" format without the time zone info
        const formattedDate = date.toISOString().split('T')[0];
        SetTransportDocumentDate(formattedDate);
      } else {
        SetTransportDocumentDate(null);
      }
    };
    
    
    const handleTasaChange = (event) => {
        setTasa(event.target.value);
    };

    const handleTaxBaseChange = (event) => {
      setTaxBase(event.target.value);
  };
    const handleDeleteDocument = async () => {
      try {
          var response = await axios.delete(`${config.apiUrl}api/documents/${id}`);
          if (response.status === 200){
            navigate('/documento/ingreso')
          }     } catch (error) {
         alert(t('Edit.Error'))// Handle the error gracefully here, e.g., show an error message to the user
     }
  };
    const handleUpdateDocument = async () => {
        const record = {
            levante: Levante,
            levanteDate: FechaLevante,
            tasa: parseFloat(Tasa),
            taxBase: parseFloat(TaxBase),
            documentDate: TransportDocumentDate,
            transportMehodId: TransportMethod
            };
        try {
            await axios.put(`${config.apiUrl}api/documents/${id}`, record, 
            {params:{
                company : companyId }});
                if (TipoMercancia === "Equipment" || TipoMercancia === "Equipo"){
                  navigate(`/facturas/${id}?provider=${ProviderId}&incoterm=${Incoterm}`)
                }else{
                  navigate(`/costos/${id}/R`);
                }
       } catch (error) {
           alert(t('Edit.Error'))// Handle the error gracefully here, e.g., show an error message to the user
       }
    };
    useEffect(() => {
      const fetchTransportData = async () => {
          try {
              const response = await axios.get(`${config.apiUrl}api/transports` , 
              {params:{
                  locale : i18n.language
              }});
              const data = response.data;

              const dropdownOptions = data.map((item) => ({
                  key: item.transportId,
                  text: item.transportName,
              }));
              setMediosTransporte(dropdownOptions);
          } catch (error) {
              console.error('Error fetching Transport data:', error);
          }
      };

      fetchTransportData();
  }, [i18n]);

    const today = useConst(new Date());

    return (
      
        <div className='card'>
            <h1>{t('Edit.Header') + id}</h1>
            <div>
  <Stack tokens={{ childrenGap: 10 }}>
    
    <DatePicker
        label={t('Edit.Date')}
        id="FechaDocument"
        firstDayOfWeek={1}
        name="Fecha Document"
        onSelectDate={handleFechaChange}
        formatDate={(date) => date.toLocaleDateString()}
        parseDateFromString={(dateStr) => {
            const date = Date.parse(dateStr);
            return isNaN(date) ? today : new Date(date);
        }}
        value={TransportDocumentDate ? new Date(TransportDocumentDate + "T00:00:00") : null}
    />
    <Dropdown
        label={t('Create.TMethod')}
        options={mediosTransporte}
        onChange={handleMedioTransporteChange}
        selectedKey={TransportMethod}
    />
    <TextField label={t('Edit.Type')} value={TipoMercancia} disabled={true} />
    <TextField label={t('Edit.Provider')} value={Provider} disabled={true} />
    
    {(TipoMercancia === "Repuesto" || TipoMercancia === "Part") && (
      <>
        <TextField label="Landed Cost" value={NumeroLC}  disabled={true} />
        <TextField label={t('Edit.LandedCostD')} value={FechaLC} onChange={handleFechaLevanteChange} disabled={true} />
      </>
    )}
    <TextField label={t('Edit.Release')} value={Levante} disabled={false} onChange={handleLevanteChange}/>
    <DatePicker
        label={t('Edit.ReleaseD')}
        id="FechaLevante"
        firstDayOfWeek={1}
        name="Fecha Levante"
        onSelectDate={handleFechaLevanteChange}
        formatDate={(date) => date.toLocaleDateString()}
        parseDateFromString={(dateStr) => {
            const date = Date.parse(dateStr);
            return isNaN(date) ? today : new Date(date);
        }}
        value={FechaLevante ? new Date(FechaLevante + "T00:00:00") : null}
    />

    <TextField label={t('Edit.Rate')} value={Tasa} onChange={handleTasaChange} type="number" />
    <TextField label={t('Edit.TaxBase')} value={TaxBase} onChange={handleTaxBaseChange} type="number" />
    
    <PrimaryButton text={t('Edit.Save')} onClick={handleUpdateDocument} />
    <PrimaryButton text={t('Edit.Delete')} onClick={handleDeleteDocument} style={{ backgroundColor: '#f00' }} />
  </Stack>
</div>

        </div>
    );
};
const mapStateToProps = (state) => ({
    companyId: state.companyId,
});

export default connect(mapStateToProps)(FormulatioEditarDocumento);
