import React from 'react';
import { connect } from 'react-redux';
import { setCompanyId } from '../actions';
import { Dropdown, mergeStyles } from "@fluentui/react";

const dropdownStyles = mergeStyles({
    backgroundColor: 'black',
    color: 'white',
    border: 'none',
    width: '150px',
    marginRight: '10px',
});

function CompanyDropdown({ companyId, setCompanyId, dropdownOptions }) {
    const handleCompanyChange = (_, option) => {
        setCompanyId(option.key);
    };

    const isDropdownDisabled = !dropdownOptions || dropdownOptions.length === 0;

    return (
        <Dropdown
            className={dropdownStyles}
            options={dropdownOptions}
            defaultSelectedKey={companyId}
            onChange={handleCompanyChange}
            disabled={isDropdownDisabled} // Disable the dropdown if no options
        />
    );
}

const mapStateToProps = (state) => ({
    companyId: state.companyId,
});

const mapDispatchToProps = {
    setCompanyId,
};

export default connect(mapStateToProps, mapDispatchToProps)(CompanyDropdown);
