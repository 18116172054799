import VistaDetalleDocumentos from "./VistaDetalleDocumentos";
import FormularioCrearDocumento from "./FormularioCrearDocumento";
import FormularioEditarDocumento from "./FormularioEditarDocumento";
import FormularioAgregarFacturas from "./FormularioAgregarFacturas";
import FormularioAgregarCostos from "./FormularioAgregarCostos";
import FormularioConsolidado from "./FormularioConsolidado";
import FormularioConsolidadoHistorico from "./FormularioConsolidadoHistorico"
import Dashboard from "./Dashboard";
import FormularioCrearDocumentoEQ from "./FormularioCrearDocumentoEQ";

const facturasRoutes = [
    { path: '/documento/ingreso', element: <FormularioCrearDocumento />},
    { path: '/documento/eq/ingreso', element: <FormularioCrearDocumentoEQ />},
    { path: '/documento', element: <VistaDetalleDocumentos />},
    { path: '/documento/:id', element: <FormularioEditarDocumento />},
    { path: '/facturas/:id', element: <FormularioAgregarFacturas />},
    { path: '/costos/:id/:reptoEquipoId', element: <FormularioAgregarCostos />},
    { path: '/consolidado/:id', element: <FormularioConsolidado /> },
    { path: '/consolidado/historico/:id', element: <FormularioConsolidadoHistorico /> },
    {path: '/dashboard', element: <Dashboard />}
    
];

export default facturasRoutes;
