import { useNavigate } from 'react-router-dom';
import {PrimaryButton} from "@fluentui/react";



const NotFound = () => {
  const navigate = useNavigate();

  return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <img src="https://stsiiapptest001.blob.core.windows.net/siiapptest/404.avif" alt="" style={{ width: '100%', maxWidth: '500px', marginBottom: '20px' }} />

          <PrimaryButton
              color="primary"
              variant="contained"
              sx={{ textTransform: 'capitalize' }}
              onClick={() => navigate(-1)}
          >
            Go Back
          </PrimaryButton>        
      </div>
  );
};

export default NotFound;
