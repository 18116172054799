import React, {useEffect, useState } from 'react';

import {Dropdown, PrimaryButton, TextField, DatePicker, Stack} from '@fluentui/react';
import {useConst} from "@fluentui/react-hooks";
import {useNavigate} from "react-router-dom";
import {connect} from "react-redux";
import axios from "axios";
import {useTranslation} from 'react-i18next'
import config from '../Admin/config'


const FormularioCrearDocumento = ({ companyId }) => {
    const [numeroDocumento, setNumeroDocumento] = useState('');
    const [incoterm, setIcoterm] = useState('');
    const [medioTransporte, setMedioTransporte] = useState(0);
    const [fechaDocumento, setFechaDocumento] = useState(new Date().toISOString());
    const [levante, setLevante] = useState('');
    const [fechaLevante, setFechaLevante] = useState(new Date().toISOString());
    
    const [mediosTransporte, setMediosTransporte] = useState([]);
    const [transportDocuments, setTransportDocuments] = useState([]);

    const {t, i18n} = useTranslation();


    const navigate = useNavigate();
    const handleNumeroDocumentoChange = (_, item) => {
        setNumeroDocumento(item ? item.key : '');
    };
    const handleLevanteChange = (event) => {
        setLevante(event.target.value);
    };

    const handleMedioTransporteChange = (_, item) => {
        setMedioTransporte(item ? item.key : '');
    };
    

    const handleFechaDocumentoChange = (date) => {
        if (date && !isNaN(date.getTime())) {
            setFechaDocumento(date.toISOString());
        } else {
            setFechaDocumento(null);
        }
    };
    const handleFechaLevanteChange = (date) => {
        if (date && !isNaN(date.getTime())) {
            setFechaLevante(date.toISOString());
        } else {
            setFechaLevante(null);
        }
    };
    
    const handleSubmit = () => {
        let data = {
            transportDocumentNumber: numeroDocumento,
            company: companyId,
            incoterm: incoterm,
            transportMethod: medioTransporte,
            transportDocumentDate: fechaDocumento,
            levante: levante,
            levanteDate: fechaLevante,
            reptoEquipo: 'R',
        };
    
        axios.post(`${config.apiUrl}api/documents/`, data, {
            headers: {
                'Content-Type': 'application/json',
            },
        })
            .then(response => response.data)
            .then(result => {
                if (result.status != null) {
                    navigate(`/documento/${data.transportDocumentNumber}`);
                } else {
                    navigate(`/404`);
                }
            })
            .catch(error => {
                alert("No se puede crear el documento con numero " + numeroDocumento);
            });
    
        setIcoterm('');
        setNumeroDocumento('');
        setLevante('')
        setMedioTransporte(0);
    };

    useEffect(() => {

        const fetchTransportDocumentData = async () => {
            try {
                const response = await axios.get( `${config.apiUrl}api/documents/avaliable`, {params:{company: companyId}});
                const data = response.data;

                const dropdownOptions = data.map((item) => ({
                    key: item,
                    text: item,
                }));
                setTransportDocuments(dropdownOptions);
            } catch (error) {
                console.error('Error fetching Vendor data:', error);
            }
        };

        fetchTransportDocumentData();
    }, [companyId]);

    useEffect(() => {
        const fetchTransportData = async () => {
            try {
                const response = await axios.get(`${config.apiUrl}api/transports` , 
                {params:{
                    locale : i18n.language
                }});
                const data = response.data;

                const dropdownOptions = data.map((item) => ({
                    key: item.transportId,
                    text: item.transportName,
                }));
                setMediosTransporte(dropdownOptions);
            } catch (error) {
                console.error('Error fetching Transport data:', error);
            }
        };

        fetchTransportData();
    }, [i18n]);

    
    const today = useConst(new Date());
    
    return (
        <div className='card'>
            <h1>{t('Create.Header')}</h1>
            
                <Stack tokens={{ childrenGap: 10 , padding: 10}} VERTICAL>

                    <Stack tokens={{ childrenGap: 10 , padding: 10}} horizontal>
                        <Dropdown
                            label={t('Create.Numero')}
                            options={transportDocuments}
                            onChange={handleNumeroDocumentoChange}
                            selectedKey={numeroDocumento}
                            styles={{ dropdown: { minWidth: '250px' } }}
                        />
                        <DatePicker
                            label={t('Create.Date')}
                            id="FechaDocumento"
                            firstDayOfWeek={1}
                            name="Fecha Documento"
                            onSelectDate={handleFechaDocumentoChange}
                            formatDate={(date) => date.toLocaleDateString()}
                            parseDateFromString={(dateStr) => {
                                const date = Date.parse(dateStr);
                                return isNaN(date) ? today : new Date(date);
                            }}
                            value={fechaDocumento ? new Date(fechaDocumento) : null}
                        />

                        <TextField label={t('Create.Release')} value={levante} onChange={handleLevanteChange} />
                        <DatePicker
                            label={t('Create.ReleaseD')}
                            id="FechaLevante"
                            firstDayOfWeek={1}
                            name="Fecha Levante"
                            onSelectDate={handleFechaLevanteChange}
                            formatDate={(date) => date.toLocaleDateString()}
                            parseDateFromString={(dateStr) => {
                                const date = Date.parse(dateStr);
                                return isNaN(date) ? today : new Date(date);
                            }}
                            value={fechaLevante ? new Date(fechaLevante) : null}
                        />
                    </Stack> 
                    <Stack tokens={{ childrenGap: 10 , padding: 10}} VERTICAL>   
                        
                        <Dropdown
                            label={t('Create.TMethod')}
                            options={mediosTransporte}
                            onChange={handleMedioTransporteChange}
                            selectedKey={medioTransporte}
                        />
                    </Stack>
                </Stack>
                <Stack tokens={{ childrenGap: 10 , padding: 10}} VERTICAL>
                        <PrimaryButton text={t('Create.Submit')} onClick={handleSubmit} />
                </Stack>
        </div>
            
    );
};
const mapStateToProps = (state) => ({
    companyId: state.companyId,
});

export default connect(mapStateToProps)(FormularioCrearDocumento);