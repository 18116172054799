import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './app/App';
import { store, persistor } from './app/store'; // Assuming you export the store and persistor from './store'
import {Provider} from "react-redux";
import {PersistGate} from "redux-persist/integration/react";
import './i18n';
import { msalConfig } from './auth/authConfig';
import { MsalProvider } from '@azure/msal-react';
import { PublicClientApplication } from '@azure/msal-browser';

const msalInstance = new PublicClientApplication(msalConfig)


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(

    <Provider store={store} >
        <PersistGate loading={null} persistor={persistor}>
            <React.StrictMode>
                <MsalProvider instance={msalInstance}>
                    <App />
                </MsalProvider>  
            </React.StrictMode>
        </PersistGate>
    </Provider>
);
