import {React, Suspense} from 'react';
import AuthValidation from './AuthValidation';
import {BrowserRouter as Router} from 'react-router-dom';
import {AuthenticatedTemplate, useMsalAuthentication} from '@azure/msal-react'
import { InteractionType } from '@azure/msal-browser';



function App() {
  
  useMsalAuthentication(InteractionType.Redirect );
    return (
        <div className="App">
            <Router>
              <AuthenticatedTemplate>
                  <AuthValidation />
              </AuthenticatedTemplate>    
            </Router>
        </div>   
    );
}

export default function WrappedApp() {
    return (
      <Suspense fallback="...loading">
          <App />
      </Suspense>
    )
  };
