import React, {useEffect, useState } from 'react';

import {Dropdown, PrimaryButton, TextField, DatePicker, Stack} from '@fluentui/react';
import {useConst} from "@fluentui/react-hooks";
import {useNavigate} from "react-router-dom";
import {connect} from "react-redux";
import axios from "axios";
import {useTranslation} from 'react-i18next'
import config from '../Admin/config'


const FormularioCrearDocumentoEq = ({ companyId }) => {
    const [numeroDocumento, setNumeroDocumento] = useState('');
    const [incoterm, setIncoterm] = useState('');
    const [medioTransporte, setMedioTransporte] = useState(0);
    const [fechaDocumento, setFechaDocumento] = useState(new Date().toISOString());
    const [levante, setLevante] = useState('');
    const [provider, setProvider] = useState('');
    const [fechaLevante, setFechaLevante] = useState(new Date().toISOString());
    
    const [mediosTransporte, setMediosTransporte] = useState([]);
    const [providers, setProviders] = useState([]);
    const [incoterms, setIncoterms] = useState([]);

    const {t, i18n} = useTranslation();


    const navigate = useNavigate();
    const handleNumeroDocumentoChange = (event) => {
        setNumeroDocumento(event.target.value);
    };
    const handleLevanteChange = (event) => {
        setLevante(event.target.value);
    };

    const handleMedioTransporteChange = (_, item) => {
        setMedioTransporte(item ? item.key : '');
    };

    const handleProviderChange = (_, item) => {
        setProvider(item ? item.key : '');
    };

    const handleIncotermChange = (_, item) => {
        setIncoterm(item ? item.key : '');
    };
    

    const handleFechaDocumentoChange = (date) => {
        if (date && !isNaN(date.getTime())) {
            setFechaDocumento(date.toISOString());
        } else {
            setFechaDocumento(null);
        }
    };
    const handleFechaLevanteChange = (date) => {
        if (date && !isNaN(date.getTime())) {
            setFechaLevante(date.toISOString());
        } else {
            setFechaLevante(null);
        }
    };
    
    const handleSubmit = () => {
        let data = {
            transportDocumentNumber: numeroDocumento,
            company: companyId,
            incoterm: incoterm,
            transportMethod: medioTransporte,
            transportDocumentDate: fechaDocumento,
            levante: levante,
            levanteDate: fechaLevante,
            provider: provider,
            reptoEquipo: 'E',
        };
    
        axios.post(`${config.apiUrl}api/documents/`, data, {
            headers: {
                'Content-Type': 'application/json',
            },
        })
            .then(response => response.data)
            .then(result => {
                if (result.status != null) {
                    navigate(`/documento/${data.transportDocumentNumber}`);
                } else {
                    navigate(`/404`);
                }
            })
            .catch(error => {
                alert("No se puede crear el documento con numero " + numeroDocumento);
                console.error('Error:', error);
            });
    
        setIncoterm('');
        setNumeroDocumento('');
        setLevante('')
        setProvider('')
        setMedioTransporte(0);
    };

    useEffect(() => {
        const fetchTransportData = async () => {
            try {
                const response = await axios.get(`${config.apiUrl}api/transports` , 
                {params:{
                    locale : i18n.language
                }});
                const data = response.data;

                const dropdownOptions = data.map((item) => ({
                    key: item.transportId,
                    text: item.transportName,
                }));
                setMediosTransporte(dropdownOptions);
            } catch (error) {
                console.error('Error fetching Transport data:', error);
            }
        };

        fetchTransportData();
    }, [i18n]);

    useEffect(() => {
        const fetchProviderData = async () => {
            try {
                const response = await axios.get(`${config.apiUrl}api/providers/ext` , 
                {params:{
                    company : companyId
                }});
                const data = response.data;

                const dropdownOptions = data.map((item) => ({
                    key: item.id,
                    text: item.id  + ' - ' + item.providerName,
                }));
                setProviders(dropdownOptions);
            } catch (error) {
                console.error('Error fetching Transport data:', error);
            }
        };
        fetchProviderData();
    }, [companyId]);

    useEffect(() => {
        const fetchIncotermData = async () => {
            try {
                const response = await axios.get(`${config.apiUrl}api/incoterms`);
                const data = response.data;

                const dropdownOptions = data.map((item) => ({
                    key: item.incoterm,
                    text: item.incoterm,
                }));
                setIncoterms(dropdownOptions);
            } catch (error) {
                console.error('Error fetching Transport data:', error);
            }
        };

        fetchIncotermData();
    }, []);

    
    const today = useConst(new Date());
    
    return (
        <div className='card'>
            <h1>{t('Create.Header')}</h1>
                <Stack tokens={{ childrenGap: 10 , padding: 10}} VERTICAL>
                    <Stack tokens={{ childrenGap: 10 , padding: 10}} horizontal>
                                                <TextField label={t('Create.Numero')} value={numeroDocumento} onChange={handleNumeroDocumentoChange} />
                        <DatePicker
                            label={t('Create.Date')}
                            id="FechaDocumento"
                            firstDayOfWeek={1}
                            name="Fecha Documento"
                            onSelectDate={handleFechaDocumentoChange}
                            formatDate={(date) => date.toLocaleDateString()}
                            parseDateFromString={(dateStr) => {
                                const date = Date.parse(dateStr);
                                return isNaN(date) ? today : new Date(date);
                            }}
                            value={fechaDocumento ? new Date(fechaDocumento) : null}
                        />

                        <TextField label={t('Create.Release')} value={levante} onChange={handleLevanteChange} />
                        <DatePicker
                            label={t('Create.ReleaseD')}
                            id="FechaLevante"
                            firstDayOfWeek={1}
                            name="Fecha Levante"
                            onSelectDate={handleFechaLevanteChange}
                            formatDate={(date) => date.toLocaleDateString()}
                            parseDateFromString={(dateStr) => {
                                const date = Date.parse(dateStr);
                                return isNaN(date) ? today : new Date(date);
                            }}
                            value={fechaLevante ? new Date(fechaLevante) : null}
                        />
                    </Stack> 
                    <Stack tokens={{ childrenGap: 10 , padding: 10}} VERTICAL>   
                        
                        <Dropdown
                            label={t('Create.TMethod')}
                            options={mediosTransporte}
                            onChange={handleMedioTransporteChange}
                            selectedKey={medioTransporte}
                        />
                        <Dropdown
                            label={t('Create.Provider')}
                            options={providers}
                            onChange={handleProviderChange}
                            selectedKey={provider}
                        />
                        <Dropdown
                            label={"Incoter"}
                            options={incoterms}
                            onChange={handleIncotermChange}
                            selectedKey={incoterm}
                        />
                    </Stack>
                </Stack>
                <Stack tokens={{ childrenGap: 10 , padding: 10}} VERTICAL>
                        <PrimaryButton text={t('Create.Submit')} onClick={handleSubmit} />
                </Stack>
        </div>
    );
};
const mapStateToProps = (state) => ({
    companyId: state.companyId,
});

export default connect(mapStateToProps)(FormularioCrearDocumentoEq);