import {connect} from "react-redux";

const Dashboard = ({ companyId}) => {
    

    return (

        <div className='card'>
            <iframe title="Dashboard" width="1140" height="541.25" src="https://app.powerbi.com/reportEmbed?reportId=4bb3aa84-0fe9-44de-ac2c-23113c802848&autoAuth=true&ctid=f49f6ea8-e3b5-4fc6-94e9-be98a5e9b02e" frameborder="0" allowFullScreen="true"></iframe>
        </div>
    );
};
const mapStateToProps = (state) => ({
    companyId: state.companyId,
});

export default  connect(mapStateToProps)(Dashboard);
