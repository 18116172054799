import { Icon, Nav, IconButton } from '@fluentui/react';
import {Link, Route, Routes} from 'react-router-dom';
import React, {useEffect, useState} from "react";
import navigation from "./navigation";
import facturasRoutes from "./views/DocumentosTransporte/FacturasRoutes";
import routes from "./routes";
const Sidebar = () => {
    const [expanded, setExpanded] = useState(false);

    const toggleExpanded = () => {
        setExpanded(!expanded);
    };

    useEffect(() => {
        // Set the height of the html and body elements to 100%
        document.documentElement.style.height = '100%';
        document.body.style.height = '100%';
    }, []);

    return (
        <div style={{ display: 'flex' }}>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    height: '150vh', // Set the height to 100vh to cover the full viewport height
                    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',
                    border: '1px solid black',
                    overflowY: 'auto', // Add this line to enable vertical scrolling
                }}
            >
                <IconButton
                    onClick={toggleExpanded}
                    style={{
                        backgroundColor: 'transparent',
                        border: 'none',
                    }}
                >
                    <Icon
                        iconName={expanded ? 'ChevronLeft' : 'AddNotes'}
                        style={{ color: 'black' }}
                    />
                </IconButton>
                <Nav
                    groups={[
                        {
                            links: navigation().map((navItem) => ({
                                ...navItem,
                                name: expanded ? navItem.name : '',
                            })),
                        },
                    ]}
                    selectedKey={window.location.pathname}
                    onRenderLink={(link) => (
                        <Link to={link.url} style={{ color: 'black' }}>
                            {expanded && link.name}
                        </Link>
                    )}
                />
            </div>
            <Routes>
                {(routes.concat(facturasRoutes)).map((route)=>(
                    <Route path={route.path} element={route.element} />
                    ))}
            </Routes>
        </div>
    );
};
 export default Sidebar;