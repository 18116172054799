import {useTranslation} from 'react-i18next'
import { useMsal } from '@azure/msal-react';

const Navigation =() => {
    const {t} = useTranslation();
    const {accounts} = useMsal();
    const roles = accounts[0]?.idTokenClaims?.roles;
    const isAdmin = roles.includes('SII_Admin');
    const isEquipment = roles.includes('Equipment');
    

    return (
        [
            {
                key: 'H',
                name: t('Navigation.Home'),
                url: '/dashboard',
                iconProps: {
                    iconName: 'Home',
                },
            },
            ((isAdmin || !isEquipment) &&{
                key: 'R',
                name: t('Navigation.Parts'),
                url: '/documento/ingreso',
                iconProps: {
                    iconName: 'WebAppBuilderFragmentCreate',
                },
            }),
            ( (isAdmin || isEquipment) &&{
                key: 'E',
                name: t('Navigation.Equipment'),
                url: '/documento/eq/ingreso',
                iconProps: {
                    iconName: 'DeliveryTruck',
                },
            }),
            {
                key: 'L',
                name: t('Navigation.Pending'),
                url: '/documento',
                iconProps: {
                    iconName: 'SeeDo',
                },
            },
            // Add more navigation items as needed
        ]
    )
};
export default Navigation;
