import {React} from 'react';
import Topbar from './Topbar';
import Sidebar from './Sidebar';
import { useMsal } from '@azure/msal-react';
import NotFound from './views/sessions/NotFound';



function AuthValidation() {
    const {accounts} = useMsal();
    const roles = accounts[0]?.idTokenClaims?.roles;

    const IsAuth = !!roles

    return (
            <div>
            {IsAuth && (
                <>
                  <Topbar />
                  <Sidebar />
                </>
            )}
            {!IsAuth && (
                <>
                  <NotFound />
                </>
            )}
            </div>
    );
}

export default AuthValidation;

